
import { Options, Vue } from "vue-class-component";
import { verification } from "@/utils/verification";
import CommonStore from "@/store/modules/common";
interface IModifyPasswordParams {
  userId: string;
  version: number;
}


@Options({
  emits: ["modifyPasswordEvent"],
  components: {},
})
export default class extends Vue {
  modifyPasswordParams: IModifyPasswordParams = {
    userId: "",
    version: 0,
  };
  get needChangePassword() {
    return this.$stores.retrieveStore(CommonStore).getNeedChangePassword;
  }

  // 表单验证
  validateOldpsw = (rule: any, value: any, callback: any) => {
    if (!value) {
      return callback(new Error('请输入原密码'))
    }
    setTimeout(() => {
      if (this.pswMsg) {
        callback(new Error(this.pswMsg))
      } else {
        callback()
      }
    }, 500)
  }

  validatepsw(rule: any, value: any, callback: any) {
    if (!value) {
      callback(new Error("请输入新密码"));
    } else if (!/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}$/.test(value)) {
      callback(new Error("新密码格式不正确"));
    } else if (!/^[@%&#|a-zA-Z0-9]+$/.test(value)) {
      callback(new Error("新密码格式不正确"));
    }
    else {
      callback();
    }
  }
  validateNewpsw = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback(new Error("请确认输入新密码"));
    } else if (this.form.formValue.newPassword !== this.form.formValue.aginNewPassword) {
      callback(new Error("两次密码输入不一致"));
    } else {
      callback();
    }
  }
  oldPassword = "";
  newPasswordErrmsg = "新密码格式不正确";
  pswMsg = '';
  form = {
    labelWidth: 100,
    updateKey: 0,
    archived: false,
    formValue: {
      password: "",
      newPassword: "",
      aginNewPassword: "",
    },
    ruleForm: {
      password: [{ required: true, validator: this.validateOldpsw, trigger: 'blur' }],
      newPassword: [{ min: 8, message: "密码长度请在8-16位内", trigger: "blur" }, { max: 16, message: "密码长度请在8-16位内", trigger: "blur" }, { required: true, validator: this.validatepsw, trigger: 'blur' }],
      aginNewPassword: [{ required: true, validator: this.validateNewpsw, trigger: 'blur' }],
    },
  };

  active(res: any) {
    this.form.archived = false;
    this.form.updateKey = new Date().getTime();
    const { id, version } = res;
    try {
      this.modifyPasswordParams.userId = id;
      this.modifyPasswordParams.version = version;
    } catch (err) {
      throw "账户异常";
    }
  }

  onFormChange(form: any) {
    this.validatePassword(form.password);
  }

  async validatePassword(s: string) {
    let modifyPassword = {
      password: s,
      userId: this.modifyPasswordParams.userId,
    };
    try {
      const [res] = await this.$api.post("/account/validatePassword", modifyPassword, {
        suppressIndicator: true,
      });
      if (res) {
        this.pswMsg = ''
      }
    } catch (err: any) {
      this.pswMsg = err.message
    }
  }

  async modifyPassword(formValue: any) {
    const modifyPassword = {
      password: formValue.password,
      newPassword: formValue.newPassword,
      ...this.modifyPasswordParams,
    };
    await this.$api.post("/account/modifyPassword", modifyPassword);
    this.$Message({
      type: "success",
      message: "修改成功",
    });
    if (this.needChangePassword) {
      this.$stores.retrieveStore(CommonStore).SET_NEED_CHANGE_PASSWORD("");
      this.$emit("modifyPasswordEvent");

    } else {
      localStorage.clear();
      this.$router.replace("/login");
    }


  }

  closeModifyModal() {
    if (this.$stores.retrieveStore(CommonStore).getNeedChangePassword) {

      this.$nextTick(() => {
        localStorage.clear();
        location.replace("/login");
      });
    } else {
      this.$emit("closeModify");
    }

  }
}
