
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from './exclusiveView/breadcrumb.vue';

@Options({
  props: {
    collapse: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  watch: {
    collapse(nval: boolean) {
      this.collapseState = nval;
    },
  },
  components: { Breadcrumb },
  computed: {
    havePermission(): boolean {
      if (!this.user) {
        this.$router.push('/login');
      }
      const permissions = (this.user as any)?.permissions || [];
      return (
        this.$route.meta.permissionCode === undefined ||
        this.$route.meta.permissionCode === '' ||
        permissions.includes(this.$route.meta.permissionCode)
      );
    },
  },
})
export default class AppMain extends Vue {
  collapseState = false;

  get user() {
    return this.obtainAccount();
  }
}
