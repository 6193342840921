
import { ArrowDown, ArrowLeftBold } from '@element-plus/icons-vue';
import { Options, Vue } from 'vue-class-component';
import CommonModule from '@/store/modules/common';
import VacationCalendar from './exclusiveView/vacationCalendar.vue';
import AccountInformation from './exclusiveView/accountInformation.vue';
import HeaderbarIcon from './headbar-icon.vue';
import { Subscription } from 'rxjs';
import { requestTimeFormat, setLocalStorage } from '@/utils/utilsHandle';
import CommonStore from '@/store/modules/common';
import {
  ACCOUNT_EXTENSION_KEY_CMVCC_SEAT,
  CmvccSeat,
} from '@ldos/shared-common';
import { OPT_VERTICAL_MEDIUM, WS_VERTICAL_MEDIUM_STATUS } from '@/constant';
import { EventSubscriptionArg } from '@velites/event';
import { xor } from 'lodash';
import _ from 'lodash';
import { Nullable } from '@velites/common';

@Options({
  components: {
    VacationCalendar,
    AccountInformation,
    ArrowDown,
    ArrowLeftBold,
    HeaderbarIcon,
  },
})
export default class Headbar extends Vue {
  updateKey = Math.random(); // 用于刷新展开/伸缩图标
  private missedCall?: Subscription; // 订阅未接通对象
  private verticalMediumStatus?: Subscription; // 订阅垂媒失效列表对象
  isShrink = false; // 菜单栏当时的状态
  accountInfoVisible = false; // 账户信息的弹层 是否启动
  missedCallObj: any = {}; // 未接来电的数量
  holidayList: any = []; //请假列表
  accountInfo = {}; // 账户信息
  verticalMedium = []; // 垂媒失效列表
  get getState() {
    return this.$stores.retrieveStore(CommonStore).getCallState;
  }

  get user(): any {
    return this.obtainAccount();
  }

  get cmvccSeat(): Nullable<CmvccSeat> {
    return this.$account.retrieveExtension<CmvccSeat>(
      ACCOUNT_EXTENSION_KEY_CMVCC_SEAT
    );
  }

  mounted() {
    this.init();
    // this.missedCallApi();
    // 是否配置了企微 如果配置企微则不调用垂媒渠道的失效列表
  }

  init() {
    this.missedCall = this.$event.subscribeEvent(
      ['api.call.missed'],
      (res: any) => {
        if (res.data) {
          this.missedCallApi();
        }
      }
    );
    const VERTICAL_MEDIUM_STATUS = this.$ws.wrappers.normal.obtainTopic(
      WS_VERTICAL_MEDIUM_STATUS
    );
    if (!VERTICAL_MEDIUM_STATUS) {
      this.$Message('暂无订阅此对象');
      return false;
    }
    let _this = this;
    this.verticalMediumStatus = this.$event.subscribeEvent(
      [VERTICAL_MEDIUM_STATUS],
      (res: any) => {
        if (res.data) {
          _this.verticalMedium = res.data || [];
        }
      }
    );
    if (this.$stores.retrieveStore(CommonStore).getNeedChangePassword) {
      this.accountInfoVisible = true;
      //  this.$nextTick(() => {
      //     this.accountInfoVisible = false;
      //   });
    }
  }

  async queryHolidays(data: any) {
    const [res] = await this.$api.post('/staff/queryHolidays', data);
    this.holidayList = res;
  }

  async missedCallApi() {
    // 未接来电的API
    [this.missedCallObj] = await this.$api.get('/call/callLogNoConllection', {
      userId: (this.user as any).id,
    });
  }

  shrink() {
    this.updateKey = Math.random();
    setTimeout(() => {
      // 点击设置菜单栏的状态
      this.isShrink = !this.isShrink;
      this.$emit('setShrinState', this.isShrink);
    }, 10);
  }

  missedCallPage() {
    this.$router.push({
      path: '/callFlow',
      query: {
        source: 'missedCall',
      },
    });
  }

  showPopver() {
    (this.$refs.vacationCalendar as any).active();
  }

  async safeCalendar(res: any) {
    res.forEach((x: any) => {
      x.leaveDate = requestTimeFormat(x.leaveDate);
    });
    await this.$api.post('/staff/saveHolidays', res);
    this.$Message({
      type: 'success',
      message: '修改成功',
    });
    (this.$refs.popverRef as any).hide();
  }

  againLoginSip() {
    // 重新登录坐席
    (this.$parent as any).againLoginSip();
  }

  openVerification(item: any) {
    let optObj = {
      name: item.label,
      key: item.channelKey,
      mobile: item.mobile,
    };
    this.$event.publishEvent(OPT_VERTICAL_MEDIUM, optObj);
  }

  /* 登出 */
  safeExit() {
    this.accountInfo = {
      tenantCode: (this.user as any).tenantCode,
      userName: (this.user as any).username,
    };
    localStorage.clear();
    setLocalStorage('LDOS_ACCOUNT_INFO', this.accountInfo);
    location.replace('/login');
  }

  unmounted() {
    this.missedCall?.unsubscribe();
  }
}
