
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isShrink: {
      type: Boolean,
      default: false
    }
  },
  emits: ['shrink'],
  setup(props, {emit}) {
    const shrink = () => {
      emit('shrink')
    }
    return {
      shrink
    }
  },
})
