
import { Options, Vue } from "vue-class-component";
import {
  Headbar,
  Sidebar,
  AppMain,
} from "./components/index";
import { verification } from "@@/../shared-common/src/util/verification";
let verticalCodeTimer: any = null; // 时间对象
const verticalNumber: number = 60;
interface IVerticalMedium {
  name: string;
  key: string;
  mobile: string | number;
}
@Options({
  components: {
    Headbar,
    Sidebar,
    AppMain,
  },
})
export default class Layout extends Vue {
  collapse = false; // 菜单收缩
  errorDialog = false; // 全局错误弹层是否启动
  errorDesc = ""; // 全局错误文本描述
  agentOfflineDialog = false; // 坐席离线弹层 是否启动
  leadDetailModalVisible = false; // 线索详情弹层 是否启动
  verticalMediumDialog = false; // 垂媒渠道登录失效获取验证码的弹层 是否启动
  verticalMediumCode = ""; // 垂媒渠道登录验证码code
  verticalMediumObj: IVerticalMedium = {
    name: "",
    key: "",
    mobile: "",
  };
  sendVerticalCode = false; // 是否发送验证码
  sendVerticalNumber = verticalNumber; // 验证码倒计时
  leadId = ""; // 线索详情弹层 => id
  querys = {};

  get user() {
    return this.obtainAccount();
  }

  get getVerticalNumber() {
    return this.sendVerticalNumber + "s";
  }

  mounted(): void {
    this.init();
  }

  init(): void {
  }

  openLeadDetail(res: any) {
    if (!res.leadId) {
      this.$Message({
        type: "warning",
        message: "线索id不能为空",
      });
      return false;
    }
    (this.$refs.LeadDetailView as any).active(res);
  }

  openVerticalMedium(res: IVerticalMedium) {
    this.verticalMediumObj = { ...res };
    if (!this.verticalMediumObj.mobile) {
      this.$Message({
        type: "warning",
        message: "手机号不可为空",
      });
      return false;
    }
    this.verticalMediumDialog = true;
    clearInterval(verticalCodeTimer);
    verticalCodeTimer = null;
    this.sendVerticalCode = false;
    this.verticalMediumCode = "";
    this.sendVerticalNumber = verticalNumber;
    this.getVerticalCode();
  }

  getVerticalCode() {
    if (!verification.telephone.test(String(this.verticalMediumObj.mobile))) {
      this.$Message({
        type: "warning",
        message: "手机号格式不正确",
      });
      return false;
    }
    if (!this.sendVerticalCode) {
      this.sendVerticalCode = true;
      verticalCodeTimer = setInterval(() => {
        if (this.sendVerticalNumber === 1) {
          clearInterval(verticalCodeTimer);
          verticalCodeTimer = null;
          this.sendVerticalNumber = verticalNumber;
          this.sendVerticalCode = false;
          return false;
        }
        this.sendVerticalNumber--;
      }, 1000);
      this.sendCode();
    }
  }
  async sendCode() {
    const [res] = await this.$api.post(
      "/channel/sendCode",
      {
        key: this.verticalMediumObj.key,
      },
      { timeout: 60000 }
    );
    this.$Message({
      type: "success",
      message: res,
    });
  }

  async verticalMediumConfirm() {
    if (!this.verticalMediumCode) {
      this.$Message({
        type: "warning",
        message: "验证码不能为空",
      });
      return false;
    }
    const [res] = await this.$api.post("/channel/checkCode", {
      key: this.verticalMediumObj.key,
      code: this.verticalMediumCode,
    });
    this.$Message({
      type: "success",
      message: res,
    });
    this.verticalMediumDialog = false;
  }

  setRegionData(org: {
    children: any[];
    key: string;
    disabled: number;
    name: string;
  }): any {
    // 设置省市区的数据
    const haveChildren = Array.isArray(org.children) && org.children.length > 0;
    return {
      label: org.name,
      value: org.key,
      disabled: org.disabled,
      children: haveChildren ? org.children.map((i) => this.setRegionData(i)) : [],
    };
  }

  openOrderDetail(res: any) {
    (this.$refs.OrderDetailView as any).active(res);
  }

  openCustomerDetail(res: any) {
    (this.$refs.CustomerDetailView as any).active(res);
  }
  setShrinState(res: boolean): void {
    // 设置菜单栏的收缩状态
    this.collapse = res;
  }

  test() {
    this.leadDetailModalVisible = true;
  }

  unmounted(): void {
  }
}
