
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    holidayList: {
      type: Array,
      default: [],
      required: false,
    },
  },
  watch: {
    holidayList(nVal: any) {
      nVal.forEach((x: any) => {
        x.amSelected = x.leaveAm === "01" ? true : false;
        x.pmSelected = x.leavePm === "01" ? true : false;
        x.leaveDate = this.$moment(x.leaveDate).format("YYYY-MM-DD");
      });
      this.calendarData = nVal;
    },
  },
})
export default class extends Vue {
  holidayList!: []; // 请假列表
  calendarData: any[] = []; // 最终选择的休假日期
  currentType = "top"; // 最终选择的类型
  currentDate = new Date(); // 默认展示日历日期
  startDate = new Date(); // 开始月份
  endDate = new Date(); // 结束月份
  prevMonthNum = 1; // 控制上一下月的变量
  prevNextNum = 1; // 控制上一月的变量 Historical vacation
  calendarList: any = ["日", "一", "二", "三", "四", "五", "六"];

  mounted(): void {
    this.$nextTick(() => {
      this.setChTxt();
    });
  }

  setChTxt() {
    let t = this.$$(".hbb-vacation-calendar-modal")
      .find(".el-calendar-table")
      .find("thead")
      .find("th");
    t.each((i: number) => {
      t[i].innerText = this.calendarList[i];
    });
  }

  get getNeedScroll(): boolean {
    if (this.$$(document.body).width() > 1366) {
      return false;
    }
    return true;
  }

  active(): void {
    // 初始化页面
    this.setQueryDate(this.currentDate);
  }

  setQueryDate(d: Date) {
    this.startDate = new Date(this.$moment(d).subtract(1, "month"));
    this.currentDate = new Date(this.$moment(d));
    this.endDate = new Date(this.$moment(d).add(1, "month"));
    this.$emit("queryDateEvent", {
      startTime: this.startDate.getTime(),
      endTime: this.endDate.getTime(),
    });
  }

  getDayData(day: Date): any {
    return this.calendarData.filter((x) => x.leaveDate === day)[0]
      ? this.calendarData.filter((x) => x.leaveDate === day)[0]
      : {};
  }
  hasItBeenSelected(day: Date): boolean {
    let curDate = this.calendarData.filter((x) => x.leaveDate === day)[0];
    if (curDate) {
      return true;
    }
    return false;
  }

  timePeriodIsMorning() {
    // 判断是否为上午
    let timeDis =
      this.$moment().startOf("day").valueOf() +
      12 * 60 * 60 * 1000 -
      new Date().valueOf();
    if (timeDis < 0) {
      // 此刻已经为下午
      return false;
    } else {
      // 此刻已经为上午
      return true;
    }
  }

  // 验证日期是否有效
  validateDate(type: string, slotProps: any): boolean {
    if (this.$moment(slotProps.data.day).isBefore(this.$moment(), "day")) {
      this.$Message({
        type: "warning",
        message: "历史记录不可修改",
      });
      return false;
    }
    // if (
    //   this.$moment(slotProps.data.day).isAfter(
    //     this.$moment().add(2, "M"),
    //     "day"
    //   )
    // ) {
    //   this.$Message({
    //     type: "error",
    //     message: "只允许申请2个月之内的假期"
    //   });
    //   return false;
    // }
    let timeDis =
      this.$moment().startOf("day").valueOf() +
      12 * 60 * 60 * 1000 -
      new Date().valueOf();
    let warnText = "";
    if (timeDis < 0) {
      // 此刻已经为下午
      warnText = "历史记录不可修改";
    } else {
      // 此刻已经为上午
      warnText = "需要提前半天请假";
    }

    if (type === "top" && this.$moment().isSame(this.$moment(slotProps.data.day), "d")) {
      this.$Message({
        type: "warning",
        message: warnText,
      });
      return false;
    }

    if (slotProps.data.day === this.$moment(new Date()).format("YYYY-MM-DD")) {
      if (type === "bottom" && timeDis < 0) {
        this.$Message({
          type: "warning",
          message: "需要提前半天请假",
        });
        return false;
      }
    }
    return true;
  }

  // 格子点击
  cellClick(type: string, slotProps: any): void | boolean {
    if (!this.validateDate(type, slotProps)) return;
    if (slotProps.data.type === "current-month") {
      let data = this.getDayData(slotProps.data.day);

      if (!data.leaveDate) {
        data = {
          leaveDate: slotProps.data.day,
          leaveAm: "00",
          leavePm: "00",
          amSelected: false,
          pmSelected: false,
        };
        this.calendarData.push(data);
      }
      let curleaveDate = this.calendarData.filter(
        (x: any) => x.leaveDate === slotProps.data.day
      )[0];
      if (type === "top") {
        curleaveDate.amSelected = !curleaveDate.amSelected;
        curleaveDate.leaveAm = this.setLeaveVal(curleaveDate.amSelected, curleaveDate.id);
      }

      if (type === "bottom") {
        curleaveDate.pmSelected = !curleaveDate.pmSelected;
        curleaveDate.leavePm = this.setLeaveVal(curleaveDate.pmSelected, curleaveDate.id);
      }
    }
  }
  // 日期点击
  dateClick(slotProps: any): void {
    if (!this.validateDate("top", slotProps)) return;
    if (!this.validateDate("bottom", slotProps)) return;
    if (slotProps.data.type === "current-month") {
      let data = this.getDayData(slotProps.data.day);
      if (!data.leaveDate) {
        data = {
          leaveDate: slotProps.data.day,
          leaveAm: "00",
          leavePm: "00",
          amSelected: false,
          pmSelected: false,
        };
        this.calendarData.push(data);
      }
      let isAllSelected = data.amSelected && data.pmSelected;
      let curleaveDate = this.calendarData.filter(
        (x: any) => x.leaveDate === slotProps.data.day
      )[0];
      curleaveDate.amSelected = curleaveDate.pmSelected = !isAllSelected;
      curleaveDate.leaveAm = this.setLeaveVal(curleaveDate.amSelected, curleaveDate.id);
      curleaveDate.leavePm = this.setLeaveVal(curleaveDate.pmSelected, curleaveDate.id);
    }
  }

  setLeaveVal(t: boolean, id: string) {
    // 设置请假日期的枚举
    return t ? "01" : id ? "02" : "00";
  }

  selectTabDate(type: string): void {
    // 快捷选择日期
    switch (type) {
      case "prev-month":
        this.currentDate = new Date(this.$moment(this.currentDate).subtract(1, "month"));
        break;
      case "today":
        this.currentDate = new Date(this.$moment());
        break;
      case "next-month":
        this.currentDate = new Date(this.$moment(this.currentDate).add(1, "month"));
        break;
      default:
        break;
    }
    this.setQueryDate(this.currentDate);
  }

  sureCheckDate(): void | boolean {
    // 确认选择休假的日期
    let finalArr =
      this.calendarData.filter((x: any) => x.amSelected || x.pmSelected || x.id) || [];

    if (!finalArr.length) {
      this.$Message({
        type: "warning",
        message: "请至少选择半天请假日期",
      });
      return false;
    }
    this.$emit("calendarEvent", finalArr);
  }
}
