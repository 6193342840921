
import AccountModifyRole from "./accountModifyRole.vue";
import AccountModifyPassword from "./accountModifyPassword.vue";
import { Options, Vue } from "vue-class-component";
import CommonStore from "@/store/modules/common";
@Options({
  components: {
    AccountModifyRole,
    AccountModifyPassword
  }
})
export default class extends Vue {
  modifyRoleVisible = false; // 修改账户角色的弹层 是否启动
  modifyPasswordVisible = false; // 修改账户密码的弹层 是否启动

  get user() {
    return this.obtainAccount();
  }
  get needChangePassword() {
    return this.$stores.retrieveStore(CommonStore).getNeedChangePassword ? true : false;
  }

  mounted() {
    this.init();
  }

  init() {
    if (this.$stores.retrieveStore(CommonStore).getNeedChangePassword) {
      setTimeout(() => {
        this.openPasswordModal();
      }, 100);

    }
  }
  openRoleModal() {
    this.modifyRoleVisible = true;
    this.$nextTick(() => {
      (this.$refs.AccountModifyRole as any).active(this.user);
    });
  }

  openPasswordModal() {
    this.modifyPasswordVisible = true;
    this.$nextTick(() => {
      (this.$refs.AccountModifyPassword as any).active(this.user);
    });
  }

  closeAccount() {
    this.$emit("closeAccountEvent");
  }

  modifyPassword() {
    this.modifyPasswordVisible = false;
    this.closeAccount();
  }

  safeExit() {
    this.$emit("safeExitEvent");
  }
}
