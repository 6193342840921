
import { Options, Vue } from "vue-class-component";
import CommonStore from "@/store/modules/common";
@Options({})
export default class extends Vue {
  targetRoleId = null;
  roleList: any[] = []; // 门店+角色列表

  active(res: any) {
    const { currentRole, roles } = res;
    try {
      this.roleList = roles;
      this.targetRoleId = currentRole.id;
    } catch (err) {
      throw "账户异常";
    }
  }

  async modifyRole() {
    const [res] = await this.$api.post("/account/switchRole", { targetRoleId: this.targetRoleId });
    this.$Message({
      type: "success",
      message: "修改成功",
    });
    location.href = this.$stores.retrieveStore(CommonStore).getFirstRouter.path;
  }

  closeModifyModal() {
    this.$emit("closeModify");
  }
}
