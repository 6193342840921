
import { Options, Vue } from "vue-class-component";
import routerData from "@/router/index";

@Options({
  watch: {
    $route() {
      this.setRouteData();
    }
  }
})
export default class MyBreadcrumb extends Vue {
  routeData = [];
  isHide = false;
  curMenu: any = {}; // 当前选择的菜单栏id

  mounted(): void {
    this.setRouteData();
  }

  setRouteData(): void | boolean {
    let routerList = routerData.options.routes;
    let curTitle = (this as any).$route.meta.parentTitle;

    if (curTitle === undefined) {
      const list: any = [{ title: (this as any).$route.meta.title, path: (this as any).$route.path }];
      this.routeData = list;
      return;
    }
    this.isHide = false;
    this.curMenu = routerList.filter((x: any) => x.meta.title === curTitle);
    if (!this.curMenu) {
      console.error("找不到对应模块，模块Title:", curTitle);
      return false;
    }
    const list: any = [];
    list.push({
      title: this.curMenu[0].meta.title,
      path: this.curMenu[0].path
    });
    if (this.curMenu[0].children) {
      for (let i = 0; i < this.curMenu[0].children.length; i++) {
        const secChild = this.curMenu[0].children[i];
        if (!secChild) {
          continue;
        }
        if ((this as any).$route.path === secChild.path) {
          // 直接相同
          list.push({
            title: secChild.meta.title,
            path: secChild.path
          });
        } else {
          // do thing
        }
      }
    }
    this.routeData = list;
  }
}
